<template>
    <div class="data">
        <div class="row">
            <div class="col-12 col-lg-6">
                <div class="cellulose-flex data__left">
                    <div class="data__illustration">
                        <img class="data__illustration-img" alt="illustration"
                             src="/src/cellulose/img/data_illustration.png" v-if="optionKey() === 'dak'" />
                    </div>
                    <div class="data__illustration-title">
                        {{ textTitle }}
                    </div>
                    <div class="data__illustration-text" v-html="textText">
                    </div>
                </div>
            </div>
            <div class="col-12 col-lg-6">
                <div class="cellulose-flex cellulose-flex--color data__right">
                    <h1 class="type__right-title form-title form-title--light">
                        <span class="stepnumber stepnumber--light">{{ this.activeStepIndex + 1 }}</span>
                        {{ optionString() }}
                    </h1>
                    <error-message :errors="this.errors"></error-message>

                    <div class="data__right-section" v-if="optionKey() === 'dak'">
                        <h2 class="data__right-section-title form-subtitle form-subtitle--light">Type dak?</h2>
                        <div class="row">
                            <div class="col-6">
                                <input type="radio" name="typedak" value="zadeldak" :id="'zadeldak'+index"
                                       v-model="store.rooftype[optionKey()]">
                                <label class="cellulose__label" :for="'zadeldak'+index">
                                    <span class="checkbox-check checkbox-check--light"></span>
                                    <span class="checkbox-label checkbox-label--light">Zadeldak</span>
                                </label>
                            </div>
                            <div class="col-6">
                                <input type="radio" name="typedak" value="schilddak" :id="'schilddak'+index"
                                       v-model="store.rooftype[optionKey()]">
                                <label class="cellulose__label" :for="'schilddak'+index">
                                    <span class="checkbox-check checkbox-check--light"></span>
                                    <span class="checkbox-label checkbox-label--light">Schilddak</span>
                                </label>
                            </div>
                        </div>
                        <div class="row">
                            <div class="col-6">
                                <input type="radio" name="typedak" value="lessenaarsdak" :id="'lessenaarsdak'+index"
                                       v-model="store.rooftype[optionKey()]">
                                <label class="cellulose__label" :for="'lessenaarsdak'+index">
                                    <span class="checkbox-check checkbox-check--light"></span>
                                    <span class="checkbox-label checkbox-label--light">Lessenaarsdak</span>
                                </label>
                            </div>
                            <div class="col-6">
                                <input type="radio" name="typedak" value="andere" :id="'andere'+index"
                                       v-model="store.rooftype[optionKey()]">
                                <label class="cellulose__label" :for="'andere'+index">
                                    <span class="checkbox-check checkbox-check--light"></span>
                                    <span class="checkbox-label checkbox-label--light">Andere</span>
                                </label>
                            </div>
                        </div>
                    </div>

                    <div class="data__right-section" v-if="optionKey() === 'dak'">
                        <h2 class="data__right-section-title form-subtitle form-subtitle--light">Onderdak aanwezig?</h2>
                        <div class="data__right-section-inputs">
                            <div class="data__right-section-inputs__left">
                                <input type="radio" value="1" id="onderdak_ja"
                                       v-model="store.underroof[optionKey()]">
                                <label class="cellulose__label pe-2" for="onderdak_ja">
                                    <span class="checkbox-check checkbox-check--light"></span>
                                    <span class="checkbox-label checkbox-label checkbox-label--light">Ja</span>
                                </label>
                                <input type="radio" value="0" id="onderdak_nee"
                                       v-model="store.underroof[optionKey()]">
                                <label class="cellulose__label p-2" for="onderdak_nee">
                                    <span class="checkbox-check checkbox-check--light"></span>
                                    <span class="checkbox-label checkbox-label checkbox-label--light">Nee</span>
                                </label>
                            </div>
                        </div>
                    </div>

                    <div class="data__right-section" v-if="optionKey() === 'dak' || optionKey() === 'tussenvloer'">
                        <h2 class="data__right-section-title form-subtitle form-subtitle--light">Plaatsing intelligent dampscherm?</h2>
                        <div class="data__right-section-inputs">
                            <div class="data__right-section-inputs__left">
                                <input type="radio" value="1" id="dampscherm_ja"
                                       v-model="store.barrier[optionKey()]">
                                <label class="cellulose__label pe-2" for="dampscherm_ja">
                                    <span class="checkbox-check checkbox-check--light"></span>
                                    <span class="checkbox-label checkbox-label checkbox-label--light">Ja</span>
                                </label>
                                <input type="radio" value="0" id="dampscherm_nee"
                                       v-model="store.barrier[optionKey()]">
                                <label class="cellulose__label p-2" for="dampscherm_nee">
                                    <span class="checkbox-check checkbox-check--light"></span>
                                    <span class="checkbox-label checkbox-label checkbox-label--light">Nee</span>
                                </label>
                            </div>
                        </div>
                    </div>

                    <div class="data__right-section" v-if="store.barrier[optionKey()] === '1'">
                        <h2 class="data__right-section-title form-subtitle form-subtitle--light">Plaatsing volledig voorzien door Verbiest?</h2>
                        <div class="data__right-section-inputs">
                            <div class="data__right-section-inputs__left">
                                <input type="radio" value="1" id="plaatsing_ja"
                                       v-model="store.placement[optionKey()]">
                                <label class="cellulose__label pe-2" for="plaatsing_ja">
                                    <span class="checkbox-check checkbox-check--light"></span>
                                    <span class="checkbox-label checkbox-label checkbox-label--light">Ja</span>
                                </label>
                                <input type="radio" value="0" id="plaatsing_nee"
                                       v-model="store.placement[optionKey()]">
                                <label class="cellulose__label p-2" for="plaatsing_nee">
                                    <span class="checkbox-check checkbox-check--light"></span>
                                    <span class="checkbox-label checkbox-label checkbox-label--light">Nee</span>
                                </label>
                            </div>
                        </div>
                    </div>

                    <div class="data__right-section" v-if="store.placement[optionKey()] === '0'">
                        <h2 class="data__right-section-title form-subtitle form-subtitle--light">Dampscherm pakket aankopen via Verbiest? (inclusief alle benodigde materialen en instructies)</h2>
                        <div class="data__right-section-inputs">
                            <div class="data__right-section-inputs__left">
                                <input type="radio" value="1" id="pakket_ja"
                                       v-model="store.package[optionKey()]">
                                <label class="cellulose__label pe-2" for="pakket_ja">
                                    <span class="checkbox-check checkbox-check--light"></span>
                                    <span class="checkbox-label checkbox-label checkbox-label--light">Ja</span>
                                </label>
                                <input type="radio" value="0" id="pakket_nee"
                                       v-model="store.package[optionKey()]">
                                <label class="cellulose__label p-2" for="pakket_nee">
                                    <span class="checkbox-check checkbox-check--light"></span>
                                    <span class="checkbox-label checkbox-label checkbox-label--light">Nee</span>
                                </label>
                            </div>
                        </div>
                    </div>

                    <div class="data__right-section" v-if="store.package[optionKey()] === '1'">
                        <div class="data__right-section-inputs">
                            <div class="data__right-section-inputs__left">
                                <input type="radio" value="1" id="levering_ja"
                                       v-model="store.delivery[optionKey()]">
                                <label class="cellulose__label pe-2" for="levering_ja">
                                    <span class="checkbox-check checkbox-check--light"></span>
                                    <span class="checkbox-label checkbox-label checkbox-label--light">Pakket leveren (prijs afhankelijk van locatie)</span>
                                </label>
                                <input type="radio" value="0" id="levering_nee"
                                       v-model="store.delivery[optionKey()]">
                                <label class="cellulose__label p-2" for="levering_nee">
                                    <span class="checkbox-check checkbox-check--light"></span>
                                    <span class="checkbox-label checkbox-label checkbox-label--light">Pakket ophalen (gratis)</span>
                                </label>
                            </div>
                        </div>
                    </div>

                    <div class="data__right-groupedsection">
                        <div class="data__right-section">
                            <h2 class="data__right-section-title form-subtitle form-subtitle--light">
                                Isolatie dikte
                            </h2>
                            <input type="number" min="0" id="isolatiedikte" class="data__right-section-input"
                                   v-model="store.thickness[optionKey()]"/>
                            <label class="cellulose__label p-2" for="isolatiedikte">cm</label>
                        </div>
                        <div class="data__right-section" v-if="optionKey() !== 'plafond_kruipkelder'">
                            <h2 class="data__right-section-title form-subtitle form-subtitle--light">Aantal m<sup>2</sup></h2>
                            <input type="number" min="0" id="vloeropbouw" class="data__right-section-input"
                                   v-model="store.size[optionKey()]"/>
                            <label class="cellulose__label p-2" for="vloeropbouw">m<sup>2</sup></label>
                        </div>
                    </div>

                    <div class="buttons">
                        <a href="#"
                           @click.stop.prevent="previousStep"
                           class="button button--white-1">
                            Vorige
                        </a>
                        <a href="#"
                           @click.stop.prevent="nextStep"
                           class="button button--white-1">
                            Volgende
                        </a>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import StepMixin from "@/mixins/StepMixin";
import {store} from '@/store/dataStore';
import ErrorMessage from "@/components/layout/Formerrors";

export default {
    name: 'DataStep',
    mixins: [StepMixin],
    components: {
        ErrorMessage
    },
    data() {
        return {
            errors: [],
            store,
            textTitle : '',
            textText : '',
            options: {
                dotSize: 22,
                height: 10,
                min: 3,
                max: 30,
                interval: 1,
                tooltip: false,
            }
        }
    },
    props: [
        'activeStep',
        'activeStepIndex'
    ],
    methods: {
        optionKey() {
            return this.store.options[this.activeStepIndex - 1]
        },
        optionString() {
            var option = this.store.suboptions[this.store.type].find(option => option.key === this.store.options[this.activeStepIndex - 1]);
            return option.value.toLowerCase();
        },
        nextStep() {
            this.validateData();

            if (!this.errors.length) {
                this.$emit('nextStep');
            }
        },
        goToStep(index) {
            this.$emit('goToStep', index);
        },
        validateData() {
            this.errors = [];

            if (this.optionKey() === 'dak' && !this.store.rooftype[this.optionKey()]) {
                this.errors.push('Gelieve een daktype aan te duiden.')
            }

            if (!this.store.thickness[this.optionKey()]) {
                this.errors.push('Gelieve een isolatie dikte in te geven.')
            }

            if (!this.store.size[this.optionKey()]) {
                this.errors.push('Gelieve het aantal m2 in te geven.')
            }

            if (this.store.package[this.optionKey()] === '1' && !this.store.delivery[this.optionKey()]) {
                this.errors.push('Gelieve aan te duiden of het pakket opgehaald of geleverd dient te worden.')
            }
        }
    },
    async mounted() {
        if (this.abortController) {
            this.abortController.abort();
        }
        this.abortController = new AbortController();

        const result = await this.$get(baseHref + '/home/ajax/getText/cellulose_stap2', '', this.abortController.signal);
        this.abortController = null;

        if (result) {
            this.textTitle = result.title;
            this.textText = result.text;
        }

        const header = document.getElementById('config__header');
        header.className = 'cellulose__header';
    },
    created() {
        this.store.options.forEach((value) => {
            if (!this.store.rooftype.hasOwnProperty(value)) {
                this.store.rooftype[value] = '';
            }

            if (!this.store.size.hasOwnProperty(value)) {
                this.store.size[value] = 0;
            }

            if (!this.store.thickness.hasOwnProperty(value)) {
                this.store.thickness[value] = 0;
            }

            if (!this.store.underroof.hasOwnProperty(value)) {
                this.store.underroof[value] = 0;
            }

            if (!this.store.barrier.hasOwnProperty(value)) {
                this.store.barrier[value] = 0;
            }
        });
    },
}
</script>
