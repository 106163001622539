import { createApp } from 'vue'
import App from './App.vue'
import dataManager from './utils/data-manager';
import vSelect from 'vue-select';
import VueKonva from 'vue-konva';

// Global Components
import FormGroup from "@/components/form/FormGroup";

const app = createApp(App);

app.use(dataManager);

// Plugins
app.component('v-select', vSelect)
app.component('form-group', FormGroup)

app.mount('#cellulose');
app.use(VueKonva);

app.directive('focus', {
    mounted: (el) => el.focus()
});