<template>
    <div class="info" ref="template">
        <div class="row">
            <div class="col-12 col-lg-6">
                <div class="cellulose-fixed cellulose-fixed--color info__left">
                    <h1 class="form-title form-title--light">
                        {{ title }}
                    </h1>
                    <div class="info__left__top-content" v-html="text">
                    </div>
                    <div class="info__left__bottom-content">
                        <div class="info__left__bottom-content-title">{{ question_title }}</div>
                        <div class="info__left__bottom-content-info" v-html="question_text">
                        </div>
                    </div>
                </div>
            </div>
            <div class="col-12 col-lg-6">
                <div class="cellulose-flex">
                    <div class="row">
                        <h1 class="info__right-title form-title">
                            <span class="stepnumber">{{ this.activeStepIndex + 1 }}</span>
                            Jouw gegevens
                        </h1>
                    </div>
                    <div class="row">
                        <div class="col-12">
                            <div class="info__right-content">
                                Je bent nog slechts 1 stapje verwijderd van jouw perfecte vloerisolatie.<br>
                                Vul nog even je gegevens in en ontvang een gericht voorstel.
                            </div>
                        </div>
                    </div>
                    <error-message :errors="this.errors"></error-message>
                    <div class="row">
                        <div class="col-12">
                            <form class="info__form" enctype='multipart/form-data' data-form>
                                <div class="row">
                                    <div class="col-6">
                                        <div class="form-group">
                                            <label class="form-label" for="voornaam">Voornaam</label>
                                            <input class="form-input" id="voornaam" type="text"
                                                   v-model="store.info.firstname" data-input required>
                                        </div>
                                    </div>
                                    <div class="col-6">
                                        <div class="form-group">
                                            <label class="form-label" for="familienaam">Familienaam</label>
                                            <input class="form-input" id="familienaam" type="text"
                                                   v-model="store.info.surname" data-input required>
                                        </div>
                                    </div>
                                </div>
                                <div class="row">
                                    <div class="col-6">
                                        <div class="form-group">
                                            <label class="form-label" for="tel">Telefoonnummer</label>
                                            <input class="form-input" id="tel" type="text" v-model="store.info.phone"
                                                   data-input>
                                            <div class="form-helptext">Indien buitenlands nummer, gelieve de landcode te gebruiken</div>
                                        </div>
                                    </div>
                                    <div class="col-6">
                                        <div class="form-group">
                                            <label class="form-label" for="email">E-mailadres</label>
                                            <input class="form-input" id="email" type="text" v-model="store.info.email"
                                                   data-input required>
                                        </div>
                                    </div>
                                </div>
                                <h1 class="form-title">Je huidig adres</h1>
                                <div class="row">
                                    <div class="col-6">
                                        <div class="form-group">
                                            <label class="form-label" for="straat">Straat</label>
                                            <input class="form-input" id="straat" type="text"
                                                   v-model="store.info.street" data-input>
                                        </div>
                                    </div>
                                    <div class="col-3">
                                        <div class="form-group">
                                            <label class="form-label" for="nr">Nr</label>
                                            <input class="form-input" id="nr" type="text" v-model="store.info.number"
                                                   data-input>
                                        </div>
                                    </div>
                                    <div class="col-3">
                                        <div class="form-group">
                                            <label class="form-label" for="bus">Bus</label>
                                            <input class="form-input" id="bus" type="text" v-model="store.info.bus"
                                                   data-input>
                                        </div>
                                    </div>
                                </div>
                                <div class="row">
                                    <div class="col-3">
                                        <div class="form-group">
                                            <label class="form-label" for="postcode">Postcode</label>
                                            <input class="form-input" id="postcode" type="text"
                                                   v-model="store.info.postalcode" data-input>
                                        </div>
                                    </div>
                                    <div class="col-9">
                                        <div class="form-group">
                                            <label class="form-label" for="gemeente">Gemeente</label>
                                            <input class="form-input" id="gemeente" type="text"
                                                   v-model="store.info.city" data-input>
                                        </div>
                                    </div>
                                </div>
                                <div class="row">
                                    <div class="col-12">
                                        <div class="form-group">
                                            <input class="form-input" type="checkbox" value="1" id="same_site"
                                                   v-model="store.info.same_siteaddress">
                                            <label class="form-label checkboxes" for="same_site">
                                                <span class="checkbox-check"></span>
                                                <span class="checkbox-label">Werfadres is hetzelfde als mijn huidige adres</span>
                                            </label>
                                        </div>
                                    </div>
                                </div>
                                <h1 class="form-title" v-if="!store.info.same_siteaddress">Je werfadres</h1>
                                <div class="row" v-if="!store.info.same_siteaddress">
                                    <div class="col-6">
                                        <div class="form-group">
                                            <label class="form-label" for="site_straat">Straat</label>
                                            <input class="form-input" id="site_straat" type="text"
                                                   v-model="store.info.site_street" data-input>
                                        </div>
                                    </div>
                                    <div class="col-3">
                                        <div class="form-group">
                                            <label class="form-label" for="site_nr">Nr</label>
                                            <input class="form-input" id="site_nr" type="text"
                                                   v-model="store.info.site_number" data-input>
                                        </div>
                                    </div>
                                    <div class="col-3">
                                        <div class="form-group">
                                            <label class="form-label" for="site_bus">Bus</label>
                                            <input class="form-input" id="site_bus" type="text"
                                                   v-model="store.info.site_bus" data-input>
                                        </div>
                                    </div>
                                </div>
                                <div class="row" v-if="!store.info.same_siteaddress">
                                    <div class="col-3">
                                        <div class="form-group">
                                            <label class="form-label" for="site_postcode">Postcode</label>
                                            <input class="form-input" id="site_postcode" type="text"
                                                   v-model="store.info.site_postalcode" data-input>
                                        </div>
                                    </div>
                                    <div class="col-9">
                                        <div class="form-group">
                                            <label class="form-label" for="site_gemeente">Gemeente</label>
                                            <input class="form-input" id="site_gemeente" type="text"
                                                   v-model="store.info.site_city" data-input>
                                        </div>
                                    </div>
                                </div>
                                <h1 class="form-title">Je geplande uitvoerdatum (indicatie)</h1>
                                <div class="row">
                                    <div class="col-6">
                                        <div class="form-group datepicker">
                                            <label class="form-label" for="datum">Datum</label>
                                            <Datepicker id="datum" class="datepicker" data-input
                                                        v-model="store.info.planned_date"
                                                        :minDate="new Date()"
                                                        :enableTimePicker="false"
                                                        autoApply
                                                        :format="format"
                                                        inputClassName="form-input"
                                            ></Datepicker>
                                        </div>
                                    </div>
                                </div>
                                <h1 class="form-title">Extra informatie</h1>
                                <div class="row">
                                    <div class="col-12">
                                        <div class="form-group upload">
                                            <label class="form-label" for="pictures">Bouwplannen/foto's</label>
                                            <input class="form-input" id="pictures" type="file"
                                                   accept="image/png,image/jpeg,image/jpg,application/pdf"
                                                   multiple @change="uploadFiles" data-input>
                                        </div>
                                    </div>
                                </div>
                                <div class="row">
                                    <div class="col-12">
                                        <div class="form-group">
                                            <input class="form-input" type="checkbox" value="1" id="accoustic"
                                                   v-model="store.info.accoustic">
                                            <label class="form-label checkboxes" for="accoustic">
                                                <span class="checkbox-check"></span>
                                                <span class="checkbox-label">
                                                    Mijn bouwproject is een meergezinswoning of appartementsproject en akoestische vloerisolatie is ook belangrijk.
                                                </span>
                                            </label>
                                        </div>
                                    </div>
                                </div>
                                <div class="row">
                                    <div class="col-12">
                                        <div class="form-group">
                                            <label class="form-label" for="remark">Opmerking</label>
                                            <textarea class="form-input" id="remark" rows="4"
                                                      v-model="store.info.remark" data-input/>
                                        </div>
                                    </div>
                                </div>
                                <div class="row">
                                    <div class="col-12">
                                        <div class="form-group">
                                            <input class="form-input" type="checkbox" value="1" id="privacy"
                                                   v-model="store.info.privacy">
                                            <label class="form-label checkboxes" for="privacy">
                                                <span class="checkbox-check"></span>
                                                <span class="checkbox-label">
                                                Ja, ik ga akkoord met de <a :href="basehref + 'privacypolicy'" target="_blank">privacy voorwaarden</a>.
                                                </span>
                                            </label>
                                        </div>
                                    </div>
                                </div>
                            </form>
                            <div class="buttons">
                                <a href="#"
                                   @click.stop.prevent="previousStep"
                                   class="button button--white-1">
                                    Vorige
                                </a>
                                <a href="#"
                                   @click.stop.prevent="submit"
                                   class="button button--color1-white">
                                    {{ submitting ? 'Bezig met verzenden...' : 'Bezorg mijn voorstel' }}
                                </a>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import StepMixin from "@/mixins/StepMixin";
import {store} from '@/store/dataStore';
import Datepicker from '@vuepic/vue-datepicker';
import ErrorMessage from "@/components/layout/Formerrors";
import parsePhoneNumber from 'libphonenumber-js'
import {toRaw} from "vue";

export default {
    name: 'InfoStep',
    mixins: [StepMixin],
    components: {ErrorMessage, Datepicker},
    props: [
        'activeStepIndex'
    ],
    data() {
        return {
            basehref: '',
            title: '',
            text: '',
            question_title: '',
            question_text: '',
            errors: [],
            submitting: false,
            store,
        }
    },
    methods: {
        format(date) {
            const day = date.getDate();
            const month = date.getMonth() + 1;
            const year = date.getFullYear();

            return `${day}/${month}/${year}`;
        },
        async uploadFiles(e) {
            const formData = new FormData();

            // add every uploaded image to the formData
            for (let i = 0; i < e.target.files.length; i++) {
                const image = e.target.files[i];
                formData.append('files[]', image);
            }

            const result = await fetch(baseHref + '/home/ajax/upload', {
                method: 'POST',
                body: formData,
                headers: new Headers({
                    'Accept': 'application/json'
                })
            });
            this.store.info.files = await result.json();
        },
        async submit() {
            this.validateForm();

            if (this.errors.length) {
                return;
            }

            if (this.abortController) {
                this.abortController.abort();
            }
            this.abortController = new AbortController();

            // const $el = e.target;
            // $el.text = 'Bezig met verzenden...';
            // $el.disabled = true;

            if (!this.submitting) {
                this.submitting = true;

                const result = await this.$post(baseHref + '/home/ajax/submit', toRaw(this.store), '', this.abortController.signal);
                this.abortController = null;

                if (result) {
                    result.success
                            ? this.nextStep()
                            : alert('Onze excuses, er liep iets mis met jouw aanvraag. Probeer later opnieuw.');
                    this.submitting = false;
                }
            }
        },
        validateForm() {
            this.errors = [];

            if (!this.store.info.firstname) {
                this.errors.push('Voornaam is verplicht in te vullen.');
            }
            if (!this.store.info.surname) {
                this.errors.push('Familienaam is verplicht in te vullen.');
            }
            if (!this.store.info.phone) {
                this.errors.push('Telefoon is verplicht in te vullen.');
            }
            if (this.store.info.phone && !this.validatePhone(this.store.info.phone)) {
                this.errors.push('Gelieve een geldig telefoonnummer in te vullen.');
            }
            if (!this.store.info.email) {
                this.errors.push('E-mail is verplicht in te vullen.');
            }
            if (this.store.info.email && !this.validateEmail(this.store.info.email)) {
                this.errors.push('Gelieve een geldig e-mailadres in te vullen.');
            }
            if (!this.store.info.street) {
                this.errors.push('Straat is verplicht in te vullen.');
            }
            if (!this.store.info.number) {
                this.errors.push('Nummer is verplicht in te vullen.');
            }
            if (!this.store.info.postalcode) {
                this.errors.push('Postcode is verplicht in te vullen.');
            }
            if (!this.store.info.city) {
                this.errors.push('Gemeente is verplicht in te vullen.');
            }
            if (!this.store.info.same_siteaddress) {
                if (!this.store.info.site_street) {
                    this.errors.push('Straat van werfadres is verplicht in te vullen.');
                }
                if (!this.store.info.site_number) {
                    this.errors.push('Nummer van werfadres is verplicht in te vullen.');
                }
                if (!this.store.info.site_postalcode) {
                    this.errors.push('Postcode van werfadres is verplicht in te vullen.');
                }
                if (!this.store.info.site_city) {
                    this.errors.push('Gemeente van werfadres is verplicht in te vullen.');
                }
            }
            if (!this.store.info.privacy) {
                this.errors.push('Gelieve akkoord te gaan met onze privacy voorwaarden.');
            }
        },
        validatePhone(phone) {
            const phoneNumber = parsePhoneNumber(phone, 'BE');
            return phoneNumber && phoneNumber.isValid();
        },
        validateEmail(email) {
            return email.match(
                    /^(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
            );
        },
    },
    async mounted() {
        this.basehref = this.$el.parentNode.dataset.baseHref;

        window.initDom(this.$refs["template"]);

        if (this.abortController) {
            this.abortController.abort();
        }
        this.abortController = new AbortController();

        const result = await this.$get(baseHref + '/home/ajax/getText/cellulose_stap3', '', this.abortController.signal);
        const resultQuestion = await this.$get(baseHref + '/home/ajax/getText/cellulose_question', '', this.abortController.signal);
        this.abortController = null;

        if (result) {
            this.title = result.title;
            this.text = result.text;
        }
        if (resultQuestion) {
            this.question_title = resultQuestion.title;
            this.question_text = resultQuestion.text;
        }

        const header = document.getElementById('config__header');
        header.className = 'cellulose__header cellulose__header--reverse';
    }
}
</script>
