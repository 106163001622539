import { reactive } from 'vue'

export const store = reactive({
    // DATA
    type: '',
    options: [],
    rooftype: {},
    underroof: {},
    thickness: {},
    size: {},
    barrier: {},
    placement: {},
    package: {},
    delivery: {},
    info: {
        firstname: '',
        surname: '',
        phone: '',
        email: '',
        street: '',
        number: '',
        bus: '',
        postalcode: '',
        city: '',
        same_siteaddress: 0,
        site_street: '',
        site_number: '',
        site_bus: '',
        site_postalcode: '',
        site_city: '',
        planned_date: '',
        files: [],
        accoustic: 0,
        remark: '',
        privacy: 0,
    },

    // GENERAL
    steps: [
        'TypeStep',
        'InfoStep',
        'ConfirmStep',
    ],
    types: [
        { key: 'nieuwbouw', value: 'Nieuwbouw', icon: 'icon-build' },
        { key: 'renovatie', value: 'Renovatie/na isolatie', icon: 'icon-renovation' },
        //{ key: 'cellulose', value: 'Cellulose', icon: 'icon-isolation' }
    ],
    suboptions: {
        nieuwbouw: [
            { key: 'dak', value: 'Dak' },
            { key: 'binnenwanden', value: 'Binnenwanden' },
            { key: 'tussenvloer', value: 'Tussenvloer' },
        ],
        renovatie: [
            { key: 'dak', value: 'Dak' },
            { key: 'binnenwanden', value: 'Binnenwanden' },
            { key: 'tussenvloer', value: 'Tussenvloer' },
            { key: 'zoldervloer', value: 'Zoldervloer losblazen' },
        ],
        // cellulose: [
        //     { key: 'tussenvloeren', value: 'Tussenvloeren' },
        //     { key: 'binnenmuren', value: 'Binnenmuren' },
        //     { key: 'zoldervloer', value: 'Zoldervloer (losblazen)' },
        //     { key: 'dakisolatie', value: 'Dakisolatie' },
        // ],
    }
})